import React from 'react';
import './FAQs.css';
import DesktopBG from '../assets/bg.png';
import MobileBG from '../assets/bg.png';
import { useNavigate, Link } from 'react-router-dom';


// Import PDFs from assets
import Pdf1 from '../assets/Howtocreateapoll_PDF.pdf';
import Pdf2 from '../assets/Howtodeletepolls_PDF.pdf';
import Pdf3 from '../assets/Howtosharepolls_PDF.pdf';
import Pdf4 from '../assets/Howtodownloadpolldata_PDF.pdf';
import Pdf5 from '../assets/Howtogenerateadatareportonline_PDF.pdf';
import Pdf6 from '../assets/Howtoresetyourpassword_PDF.pdf';
import Pdf7 from '../assets/HowdoISubscribetootherOPINARIusers_PDF.pdf';
import Pdf8 from '../assets/HowdoIreportpolls_PDF.pdf';
import Pdf9 from '../assets/HowdoIreportpolls_PDF.pdf';
import Pdf11 from '../assets/Howtocreateconnectedpolls_PDF.pdf';
import Pdf12 from '../assets/Howtoresetyourpassword_PDF.pdf';
import Pdf13 from '../assets/HowdoISubscribetootherOPINARIusers_PDF.pdf';
import Pdf14 from '../assets/HowdoIreportpolls_PDF.pdf';
import Pdf15 from '../assets/HowdoIreportpolls_PDF.pdf';
import Pdf16 from '../assets/Howtocreateconnectedpolls_PDF.pdf';
import Pdf17 from '../assets/Howtocreateconnectedpolls_PDF.pdf';
import Pdf10 from '../assets/Howtocreateconnectedpolls_PDF.pdf';




import Logo from '../assets/logo.png';
import HeroImg from '../assets/Hero.png';
import YouTubeIcon from '../assets/youtube.png';
import FacebookIcon from '../assets/facebook.png';
import InstagramIcon from '../assets/instagram.png';
import LinkedInIcon from '../assets/linkedin.png';
import PinterestIcon from '../assets/pinterest.png';
import RedditIcon from '../assets/reddit.png';
import SnapchatIcon from '../assets/snapchat.png';
import TikTokIcon from '../assets/tiktok.png';
import XIcon from '../assets/x.png';
import OpinionsImg from '../assets/opinions.png';
import FlexibilityImg from '../assets/flexibility.png';
import HandledImg from '../assets/handled.png';

const FAQPage = () => {
  const isDesktop = window.innerWidth >= 768;

  const backgroundStyle = {
    backgroundImage: `url(${isDesktop ? DesktopBG : MobileBG})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  };

  const openPdf = (pdfSrc) => {
    window.open(pdfSrc, '_blank');
  };

  const faqItems = [
    { pdf: Pdf11, question: "Why would I use OPINARI?", description: "Advertisers pay a premium for the engaged, ready to purchase audiences OPINARI produces." },
    { pdf: Pdf11, question: "How’s this possible?", description: "OPINARI’s revolutionary patent-pending colorwheel enables next level user engagement because of the speed and ease of our thumb driven mobile-friendly navigation." },
    { pdf: Pdf11, question: "Who Provides our Ads?", description: "Taboola™, the World’s leading open web advertising platform that delivers over 600 million ad impressions every day around the world." },
    { 
      pdf: Pdf11, 
      question: "How much money can I make?", 
      description: (
        <>
          Lots… check out our <Link to="/?openCalculator=true" style={{ color: '#65a8fd', textDecoration: 'underline' }}>Revenue Calculator</Link>  to see exactly how much money you could be making using OPINARI.

        </>
      )
      
    },
    { pdf: Pdf11, question: "How do I make money?", description: "Anytime a user clicks on an ad after they have voted you will earn money. You will be able to track and see how much money you’re making anytime inside your own dashboard." },
    { pdf: Pdf12, question: "What is the Rev share?", description: "OPINARI shares all advertising revenue transparently on a 50/50 percent sharing basis." },
    { pdf: Pdf13, question: "How do I get paid?", description: "All advertising earnings are processed via PayPal." },
    { pdf: Pdf14, question: "When do I get paid?", description: "Every month on a net 45-day schedule. You can see your total earnings across all polls or one-by-one in your own private dashboard area anytime." },
    { pdf: Pdf15, question: "How long does it take to start?", description: "Typically within a few minutes we approve polls, once our team has had a quick look at it to ensure it conforms to our editorial and ad partner guidelines." },
    { pdf: Pdf16, question: "How do I make a poll?", description: "Navigate to the Dashboard page by signing in. Once in the Dashboard simply click the “+” plus button." },
    { pdf: Pdf17, question: "How do I delete polls?", description: "Select the poll you want to delete and then scroll to the bottom of the poll and click “Delete Poll” button." },
    { pdf: Pdf1, question: "How do I share polls?", description: "Click the share icon in the top right corner of every poll that you want to share - it’s the arrow icon." },
    { pdf: Pdf2, question: "How do I download my poll data?", description: "Click the “Download Data” button within the tools dropdown located in the top left hand corner of your poll - it’s the gear icon." },
    { pdf: Pdf3, question: "How do I generate a report?", description: "Click the “Data Report” button within the tools dropdown located in the top left hand corner of the poll - it’s the gear icon." },
    { pdf: Pdf6, question: "How do I reset my password?", description: "Click the \"Login Button\" in the top right hand corner of the home page. Click the \"Forgot Password\" button below login info. Enter in account email and hit \"Send Reset Link\". Follow the instructions within the password reset email." },
    { pdf: Pdf8, question: "How do I report polls?", description: "Click the three dots in the top left hand corner of the poll you wish to report. Follow the instructions within the report poll window. Once submitted our team will review the poll and take further action." },
    { pdf: Pdf9, question: "How do I unsubscribe to emails?", description: "Click the three dots in the top left hand corner of the poll you wish to report. Follow the instructions within the report poll window. Once submitted our team will review the poll and take further action." },
   

  ];

  return (
    <div className="faq-page-wrapper">
      <div style={backgroundStyle}></div>
      <div className="faq-page">
        <section className="faq-section-description">
          <h1 className="faq-title">FAQ</h1>
          <p className="faq-intro">
            What can we help you find? For step-by-step guides, click on your desired question, and a PDF will open in a new tab!
          </p>
        </section>

        <div className="faq-grid">
          {faqItems.map((item, index) => (
            <div 
              key={index} 
              className="faq-item"
            >
              <div className="faq-item-content">
                <h3 className="faq-item-title">{`${item.question}`}</h3>
                <p className="faq-item-description">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <footer className="footer">
        <div className="footer-container">
          <div className="footer-logo-section">
            <img src={Logo} alt="Logo" className="footer-logo" />
          </div>

          <div className="footer-links">
            <ul>
              <li><a href="/">Home</a></li>
              <li><a href="/about">About</a></li>
              <li><a href="/contact">Contact</a></li>
              <li><a href="/FAQs">FAQs</a></li>
            </ul>
          </div>

          <div className="footer-socials">
            <img src={YouTubeIcon} alt="YouTube" />
            <img src={FacebookIcon} alt="Facebook" />
            <img src={InstagramIcon} alt="Instagram" />
            <img src={LinkedInIcon} alt="LinkedIn" />
            <img src={TikTokIcon} alt="TikTok" />
          </div>
        </div>

        <div className="footer-bottom">
          <p>&copy; 2024 OPINARI. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default FAQPage;