import React, { useState, useEffect } from "react";
import './Calculator.css';


const Calculator = () => {
  const [averageViews, setAverageViews] = useState("350000");
  const [engagementRate, setEngagementRate] = useState("6");
  const [numberOfPolls, setNumberOfPolls] = useState(5);
  const [engagedUsers, setEngagedUsers] = useState(0);
  const [finalEngagementRate, setFinalEngagementRate] = useState(10); // Default: 10% of engaged users
  const [votingRate, setVotingRate] = useState(2); // Default: 50% voting rate
  const [moneyPerVote, setMoneyPerVote] = useState(0.15); // Default: $0.10 per vote
  const [calculatedVotes, setCalculatedVotes] = useState(0);
  const [potentialEarnings, setPotentialEarnings] = useState(0);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [selectedMode, setSelectedMode] = useState("Average");
  const isMobile = window.innerWidth <= 600;


  const calculateResults = () => {
    const views = parseFloat(averageViews);
    const engagement = parseFloat(engagementRate);
    const polls = parseInt(numberOfPolls);
    const PPC = 1.0; // Price per click (PPC)
    const adClickRate = 0.30; // Ad click rate (35%)
  
    if (
      isNaN(views) ||
      isNaN(engagement) ||
      isNaN(polls) ||
      engagement <= 0 ||
      views <= 0 ||
      polls <= 0
    ) {
      alert("Please enter valid positive numbers for all fields.");
      return;
    }
  
    // Calculation Steps
    const engaged = (views * engagement) / 100; // Calculate engaged users
    const finalEngaged = engaged * (finalEngagementRate / 100); // Adjust based on selected engagement rate
    const votes = finalEngaged / votingRate; // Adjust based on selected voting rate
    const adClicks = votes * adClickRate; // Calculate ad clicks
    const earnings = adClicks * PPC; // Calculate earnings from ad clicks
    const total = earnings * polls; // Total earnings for all polls
  
    // Update state with breakdown values
    setEngagedUsers(engaged.toFixed(0));
    setCalculatedVotes(votes.toFixed(0));
    setPotentialEarnings(earnings.toFixed(2)); // Earnings for one poll
    setTotalEarnings(total.toFixed(2)); // Total earnings for all polls
  };
  


  const handleModeChange = (mode) => {
    setSelectedMode(mode);
    if (mode === "Conservative") {
      setMoneyPerVote(0.12);
      setFinalEngagementRate(12);
      setVotingRate(2);
    } else if (mode === "Average") {
      setMoneyPerVote(0.14);
      setFinalEngagementRate(15);
      setVotingRate(1.65);
    } else if (mode === "Generous") {
      setMoneyPerVote(0.22);
      setFinalEngagementRate(20);
      setVotingRate(1.33);
    }
  };

  // Perform the initial calculation on component mount
  useEffect(() => {
    calculateResults();
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <div style={styles.container}>
      <div style={styles.calculator}>
        <h1 style={styles.title}>Poll Earnings Calculator</h1>
        <div style={styles.inputContainer}>
        <label style={styles.label}>Average Post Views</label>

  <input
    type="text"
    placeholder="Average Post Views"
    value={averageViews.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
    onChange={(e) => {
      const value = e.target.value.replace(/,/g, ""); // Remove commas for calculations
      if (!isNaN(value)) {
        setAverageViews(value);
      }
    }}
    style={styles.input}
  />
        </div>
        <div style={styles.inputContainer}>
  <label style={styles.label}>Post Engagement Rate (%)</label>
  <input
    type="text"
    placeholder="Enter Engagement Rate"
    value={engagementRate} // Keep it simple with just the value
    onChange={(e) => {
      const value = e.target.value.replace(/[^0-9.]/g, ""); // Allow only numbers
      setEngagementRate(value); // Update state directly
    }}
    style={styles.input}
  />
</div>




        <div style={styles.inputContainer}>
          <label style={styles.label}>Number of Polls</label>
          <input
            type="number"
            placeholder="Enter number of polls"
            value={numberOfPolls}
            onChange={(e) => setNumberOfPolls(e.target.value)}
            style={styles.input}
          />
        </div>
        {/* <div style={styles.modeButtons}>
  <button
    className={`mode-button ${selectedMode === "Conservative" ? "active" : ""}`}
    onClick={() => handleModeChange("Conservative")}
  >
    Conservative
  </button>
  <button
    className={`mode-button ${selectedMode === "Average" ? "active" : ""}`}
    onClick={() => handleModeChange("Average")}
  >
    Average
  </button>
 
</div> */}
        <button onClick={calculateResults} style={styles.calculateButton}>
          Calculate
        </button>
        <div style={styles.results}>
  {/* <h2 style={styles.resultTitle}>Calculation Breakdown</h2> */}
  <div style={styles.breakdown}>
  <p style={styles.resultItem}>
    <div>Engaged Followers</div> {engagedUsers}
  </p>
  <div style={styles.divider}></div>
  <p style={styles.resultItem}>
    <div>Followers to Poll (10%)</div> {(engagedUsers * (finalEngagementRate / 100)).toFixed(0)}
  </p>
  <div style={styles.divider}></div>
  <p style={styles.resultItem}>
    <div>Follower Poll Votes (50%)</div> {calculatedVotes}
  </p>
  <div style={styles.divider}></div>
  <p style={styles.resultItem}>
    <div>Follower Ad Clicks (30%)</div> {(calculatedVotes * 0.30).toFixed(0)}
  </p>
  <div style={styles.divider}></div>
  <p style={styles.resultItem}>
    <div>Price Per Click (PPC)</div> {"$1.00"}
  </p>
  <div style={styles.divider}></div>
  <p style={styles.resultItem}>
    <div>Earnings per Poll</div> ${potentialEarnings}
  </p>
</div>
<div style={styles.finalAmount}>
  <span style={styles.finalLabel}>Estimated Earnings</span>
  <span style={styles.finalValue}>
    ${parseFloat(totalEarnings).toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })} USD
  </span>
</div>


</div>

      </div>
    </div>
  );
};

const isMobile = window.innerWidth <= 600;


const styles = {
    
    container: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px",
    },
    calculator: {
        backgroundColor: "#151515",
        borderRadius: "10px",
        padding: "20px",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.5)",
        minWidth: isMobile ? "100px" : "500px", // Adjust minWidth based on screen size
        maxWidth: "100%", // Allow scaling
      },
    title: {
      textAlign: "center",
      fontSize: "1.5em",
      marginBottom: "20px",
    },
    inputContainer: {
      marginBottom: "15px",
    },
    label: {
      fontSize: "0.9em",
      marginBottom: "5px",
      display: "block",
    },
    input: {
      width: "100%",
      padding: "8px",
      borderRadius: "8px",
      border: "1px solid #555",
      backgroundColor: "#151515",
      color: "#FFF",
      fontSize: "1em",
      outline: "none",
    },
    calculateButton: {
      width: "100%",
      padding: "10px",
      borderRadius: "8px",
      border: "none",
      backgroundColor: "#1414F4",
      fontSize: "1em",
      fontWeight: "bold",
      cursor: "pointer",
      marginTop: "10px",
      transition: "background-color 0.3s",
    },
    results: {
      marginTop: "20px",
      textAlign: "left",
    },
    breakdown: {
      backgroundColor: "#222222",
      padding: "15px",
      borderRadius: "8px",
      marginBottom: "20px",
    },
    resultItem: {
      fontSize: "0.9em",
      marginBottom: "10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    variableInput: {
      marginLeft: "10px",
      width: "60px",
      padding: "5px",
      fontSize: "0.9em",
      borderRadius: "5px",
      border: "1px solid #555",
      backgroundColor: "#1e1e1e",
      color: "#fff",
    },
    finalAmount: {
        marginTop: "20px",
        padding: "20px",
        backgroundColor: "#252525",
        borderRadius: "12px",
        textAlign: "center",
        fontSize: "1.5em",
        display: "flex",
        flexDirection: "column", // Ensure items are stacked vertically
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.5)",
      },
      finalLabel: {
        color: "#AAAAAA",
        fontSize: "1em",
        marginBottom: "10px", // Add space between the label and value
      },
      finalValue: {
        color: "rgb(117 255 122)",
        fontSize: "1.6em", // Make the final value stand out more
      },
    divider: {
      height: "1px",
      backgroundColor: "#444",
      margin: "10px 0",
    },
  
    /* Add styles for mobile responsiveness */
    "@media (max-width: 600px)": {
        calculator: {
            padding: "15px",
            minWidth: "100px", // Adjust minWidth for mobile
            maxWidth: "100%", // Allow it to scale fully on smaller screens
          },
      title: {
        fontSize: "1.2em",
      },
      input: {
        fontSize: "0.9em",
        padding: "8px",
      },
      calculateButton: {
        padding: "8px",
        fontSize: "0.9em",
      },
      resultItem: {
        fontSize: "0.8em",
      },
      variableInput: {
        width: "50px",
        padding: "4px",
        fontSize: "0.8em",
      },
      finalAmount: {
        fontSize: "1.2em",
      },
    },
  };
  

export default Calculator;
