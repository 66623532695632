import React from 'react';
import MaxImage from '../assets/max.png'; // Replace with actual image paths
import DavidImage from '../assets/david.png';
import WillImage from '../assets/will.png';
import './Team.css';
import DesktopBG from '../assets/bg.png'; // Same background as FAQ
import MobileBG from '../assets/bg.png';

const Team = () => {
  const teamMembers = [
    {
      name: 'Max Stewart',
      role: 'Master and Commander (CEO)',
      image: MaxImage,
      bio: `Hey, I’m Max Stewart, Captain of the Ship here at OPINARI. As the former Business Development Manager at Txtify, I helped scale an innovative lead generation business to a successful acquisition by Fortune 500 giant Constant Contact. Now, I’m focused on leading this amazing team in building an influencer-first monetization platform! 
      Now that being said I'm not perfect and I am indeed a leafs fan… which shows commitment right 😅 (Its our year)!!!`,
      favorites: {
        games: ['Halo Reach', 'Forza Horizon 3', 'Call of Duty'],
        movies: ['Star Wars Revenge of the Sith', 'Top Gun: Maverick', 'Rush'],
        songs: ['Money for nothing - Dire Straits', 'Catch a star - Men At Work', 'Panama - Van Halen'],
        sports: ['Toronto Maple Leafs', 'Mercedes F1 (Lewis Hamilton 8-time world Champion… 8 in my heart at least🫠)', 'Brighton and Hove Albion FC'],
      },
    },
    {
      name: 'David Stewart',
      role: 'Code Conjurer (CTO)',
      image: DavidImage,
      bio: `Hey, I’m David! Most people think CTO stands for Chief Technology Officer, but it actually means Chief of 'Don’t Mess Up the Code or We’re Screwed'. I’m the guy keeping the tech ship afloat at OPINARI, making sure the gears turn, the code doesn’t implode, and everyone else can sleep soundly. 
      Previously, I worked as an AI Specialist at Txtify™, where I played a key role in developing innovative lead generation technology. The success of our platform led to an acquisition by Constant Contact. Now, I’m bringing that experience to OPINARI, focusing on building scalable solutions to empower influencers and creators to make more money!`,
      favorites: {
        games: ['COD BO3 Zombies', 'COD BO1', 'Minecraft', 'Halo Reach'],
        movies: ['Interstellar', 'Apollo 13', 'Django Unchained', 'Star Trek 2009'],
        songs: ['Subwoofer Lullaby - Minecraft', 'Hometown - Morgan Wallen', 'Interstellar Main Theme - Hans Zimmer'],
        sports: ['Toronto Maple Leafs', 'Hamilton Tiger Cats'],
      },
    },
    {
      name: 'Will Baayen',
      role: 'Chief Marketing Officer (CMO)',
      image: WillImage,
      bio: `Hey, I’m Will Baayen, CMO at OPINARI, which also means “Charming Marketing Overlord”. With an Honors Bachelor of Business Administration degree and two years of experience running my own media company, I bring extensive insights into business development, social media management, and polling creation. I’m thrilled to help lead this incredible team in building an influencer-first monetization platform! Yes, I’m also a Leafs fan…`,
      favorites: {
        games: ['God of War: Ragnarök', 'Ghost of Tsushima', 'OG Fortnite (Season 1-7)'],
        movies: ['Interstellar', 'Harry Potter and the Goblet of Fire', 'Dune: Part Two', 'The Social Network'],
        songs: ['Resonance - Home', 'Show me How - Men I Trust', 'Money Trees - Kendrick Lamar (feat. Jay Rock)'],
        sports: ['Toronto Maple Leafs', 'Bandwagon Buffalo Bills / Philadelphia Eagles Fan'],
      },
    },
  ];

  const isDesktop = window.innerWidth >= 768;

  const backgroundStyle = {
    backgroundImage: `url(${isDesktop ? DesktopBG : MobileBG})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  };

  return (
    <div className="team-page-wrapper">
      <div style={backgroundStyle}></div>
      <div className="team">
        <h1>TEAM</h1>
        <div className="team-grid">
          {teamMembers.map((member, index) => (
            <div key={index} className="team-card">
              <div className="left-section">
                <img src={member.image} alt={member.name} className="profile-image" />
                <div className="title">
                  <h2>{member.name}</h2>
                  <h3>{member.role}</h3>
                </div>
              </div>
              <div className="right-section">
                <p>{member.bio}</p>
                <div className="favorites">
                  <h4>Favorite Video Games:</h4>
                  <ul>
                    {member.favorites.games.map((game, i) => (
                      <li key={i}>{game}</li>
                    ))}
                  </ul>
                  <h4>Favorite Movies:</h4>
                  <ul>
                    {member.favorites.movies.map((movie, i) => (
                      <li key={i}>{movie}</li>
                    ))}
                  </ul>
                  <h4>Favorite Songs:</h4>
                  <ul>
                    {member.favorites.songs.map((song, i) => (
                      <li key={i}>{song}</li>
                    ))}
                  </ul>
                  <h4>Favorite Sports Teams:</h4>
                  <ul>
                    {member.favorites.sports.map((team, i) => (
                      <li key={i}>{team}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
