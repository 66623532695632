import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  AreaChart,
  Area,
} from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "./Revenue.css";

const Revenue = ({ userId }) => {
  const [polls, setPolls] = useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortOption, setSortOption] = useState("highest"); // Default: Sort by highest earning
  const [dateRange, setDateRange] = useState("all"); // Default: All time
  const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchRevenueData = async () => {
  //     try {
  //       setLoading(true); // Set loading to true at the start
  
  //       const revenueResponse = await fetch('/revenue-summary', {
  //         method: 'POST',
  //         headers: { 'Content-Type': 'application/json' },
  //         body: JSON.stringify({
  //           userId, // Send the user ID
  //           baseDomain: window.location.origin, // Send the base domain dynamically
  //         }),
  //       });
  
  //       if (!revenueResponse.ok) {
  //         throw new Error('Failed to fetch revenue summary');
  //       }
  
  //       const { revenueData } = await revenueResponse.json();
  
  //       setRevenueData(revenueData);
  //       setFilteredData(revenueData);
  //     } catch (error) {
  //       console.error('Error fetching revenue data:', error);
  //     } finally {
  //       setLoading(false); // Ensure loading state is reset
  //     }
  //   };
  
  //   fetchRevenueData();
  // }, [userId]);
  
  
  
  



  
  


  useEffect(() => {
    let filtered = [...revenueData];
  
    // Apply date range filter
    if (dateRange !== "all") {
      const now = new Date();
      let rangeStart = null;
  
      if (dateRange === "last-week") {
        rangeStart = new Date(now);
        rangeStart.setDate(now.getDate() - 7); // Last 7 days
      } else if (dateRange === "last-month") {
        rangeStart = new Date(now);
        rangeStart.setMonth(now.getMonth() - 1); // Last 1 month
      }
  
      if (rangeStart) {
        filtered = filtered.map((item) => ({
          ...item,
          data: item.data.filter((entry) => new Date(entry.date) >= rangeStart),
        }));
      }
    }
  
    // Group and aggregate data
    filtered = filtered.map((item) => ({
      ...item,
      data: groupDataByRange(item.data, dateRange),
    }));
  
    // Apply sort filter
    if (sortOption === "highest") {
      filtered.sort((a, b) => {
        const totalA = a.data.reduce((sum, entry) => sum + (parseFloat(entry.revenue) || 0), 0);
        const totalB = b.data.reduce((sum, entry) => sum + (parseFloat(entry.revenue) || 0), 0);
        return totalB - totalA; // Descending order
      });
    } else if (sortOption === "lowest") {
      filtered.sort((a, b) => {
        const totalA = a.data.reduce((sum, entry) => sum + (parseFloat(entry.revenue) || 0), 0);
        const totalB = b.data.reduce((sum, entry) => sum + (parseFloat(entry.revenue) || 0), 0);
        return totalA - totalB; // Ascending order
      });
    }
  
    setFilteredData(filtered);
  }, [sortOption, dateRange, revenueData]);
  


  const groupDataByRange = (data, range) => {
    const now = new Date();
  
    if (range === "all") {
      // Group by month
      const grouped = data.reduce((acc, entry) => {
        const month = new Date(entry.date).toLocaleString("default", { month: "short" });
        if (!acc[month]) acc[month] = { impressions: 0, clicks: 0, revenue: 0 };
        acc[month].impressions += entry.impressions;
        acc[month].clicks += entry.clicks;
        acc[month].revenue += parseFloat(entry.revenue);
        return acc;
      }, {});
      
      // Exclude incomplete months (current month)
      const currentMonth = now.toLocaleString("default", { month: "short" });
      const monthsOrder = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      return Object.entries(grouped)
        .filter(([key]) => key !== currentMonth) // Exclude current month
        .sort((a, b) => monthsOrder.indexOf(a[0]) - monthsOrder.indexOf(b[0]))
        .map(([key, value]) => ({ date: key, ...value }));
    }
  
    if (range === "last-month") {
      // Group by week
      const grouped = data.reduce((acc, entry) => {
        const week = `Week ${Math.ceil(new Date(entry.date).getDate() / 7)}`;
        if (!acc[week]) acc[week] = { impressions: 0, clicks: 0, revenue: 0 };
        acc[week].impressions += entry.impressions;
        acc[week].clicks += entry.clicks;
        acc[week].revenue += parseFloat(entry.revenue);
        return acc;
      }, {});
    
      // Ensure weeks are in order and filter invalid weeks
      const weeks = ["Week 1", "Week 2", "Week 3", "Week 4"]; // Only valid weeks
      return Object.entries(grouped)
        .filter(([key]) => weeks.includes(key)) // Exclude Week 5
        .sort((a, b) => parseInt(a[0].split(" ")[1]) - parseInt(b[0].split(" ")[1])) // Sort weeks
        .map(([key, value]) => ({ date: key, ...value }));
    }
    
  
    if (range === "last-week") {
      // Group by day
      const grouped = data.reduce((acc, entry) => {
        const day = new Date(entry.date).toLocaleString("default", { weekday: "short" });
        if (!acc[day]) acc[day] = { impressions: 0, clicks: 0, revenue: 0 };
        acc[day].impressions += entry.impressions;
        acc[day].clicks += entry.clicks;
        acc[day].revenue += parseFloat(entry.revenue);
        return acc;
      }, {});
      
      // Days are always complete, so no filtering is required here
      const daysOrder = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      return Object.entries(grouped)
        .sort((a, b) => daysOrder.indexOf(a[0]) - daysOrder.indexOf(b[0]))
        .map(([key, value]) => ({ date: key, ...value }));
    }
  
    return data; // Default: No grouping
  };
  
  
  const calculateOverallData = () => {
    const overallData = {};
    filteredData.forEach((poll) => {
      poll.data.forEach((entry) => {
        if (!overallData[entry.date]) {
          overallData[entry.date] = { impressions: 0, clicks: 0, revenue: 0 };
        }
        overallData[entry.date].impressions += entry.impressions;
        overallData[entry.date].clicks += entry.clicks;
        overallData[entry.date].revenue += parseFloat(entry.revenue || 0);
      });
    });
  
    // Convert the object into an array sorted by date
    return Object.entries(overallData)
      .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
      .map(([date, data]) => ({
        date,
        ...data,
      }));
  };
  


  const calculateTotalEarnings = () => {
    return filteredData.reduce((total, poll) => {
      return (
        total +
        poll.data.reduce((sum, entry) => sum + (parseFloat(entry.revenue) || 0), 0)
      );
    }, 0);
  };

  

  

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "#1e1e1e",
            padding: "10px",
            borderRadius: "5px",
            color: "#fff",
          }}
        >
          <p>{label}</p>
          <p>
            <span style={{ fontWeight: "bold", color: '#64d169' }}>revenue:</span> ${parseFloat(payload[0].value).toFixed(2)}
          </p>
        </div>
      );
    }
    return null;
  };
  

  return (
    
    <div className="revenue">
      <div className="filters">
  <select value={sortOption} onChange={(e) => setSortOption(e.target.value)}>
    <option value="highest">Sort by: Highest Earning</option>
    <option value="lowest">Sort by: Lowest Earning</option>
  </select>
  <select value={dateRange} onChange={(e) => setDateRange(e.target.value)}>
    <option value="all">All Time</option>
    <option value="last-month">Last Month</option>
    <option value="last-week">Last Week</option>
    
  </select>
</div>



<div className="overall-graph-container">
<div className="total-earnings">
  <h1>
    Gross Earnings:{" "}
    <span className="earnings-amount">
      ${calculateTotalEarnings().toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
    </span>
  </h1>
</div>
  <AreaChart
    width={800}
    height={300}
    data={calculateOverallData()} // Use the aggregated data
    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
  >
    <CartesianGrid strokeDasharray="3 3" stroke="#2a2a2a" />
    <XAxis
      dataKey="date"
      tick={{ fill: "#888", fontSize: 12 }}
      axisLine={{ stroke: "#444" }}
    />
    <YAxis
      tickFormatter={(value) => parseFloat(value).toFixed(2)} // Format with 2 decimals
      tick={{ fill: "#888", fontSize: 12 }}
      axisLine={{ stroke: "#444" }}
    />
    <Tooltip
      content={({ active, payload, label }) =>
        active &&
        payload &&
        payload.length && (
          <div
            style={{
              backgroundColor: "#1e1e1e",
              padding: "10px",
              borderRadius: "5px",
              color: "#fff",
            }}
          >
            <p>{label}</p>
            <p>Revenue: ${parseFloat(payload[0].value).toFixed(2)}</p>
          </div>
        )
      }
    />
    <Area
      type="monotone"
      dataKey="revenue"
      stroke="#4caf50"
      fill="rgba(76, 175, 80, 0.2)"
    />
  </AreaChart>
</div>








      {loading ? (
        <p>Loading...</p>
      ) : (
        
        <div className="card-grid">
          {filteredData.map((revenue, index) => {
            const totalImpressions = revenue.data.reduce(
              (sum, entry) => sum + (entry.impressions || 0),
              0
            );
            const totalClicks = revenue.data.reduce(
              (sum, entry) => sum + (entry.clicks || 0),
              0
            );
            const totalRevenue = revenue.data.reduce(
              (sum, entry) => sum + (parseFloat(entry.revenue) || 0),
              0
            );

            const maxRevenue = Math.max(...revenue.data.map((entry) => entry.revenue || 0), 10);

            return (
              <div key={index} className="revenue-card">
                <div
                  className="redirect-icon-container"
                  onClick={() => navigate(`/poll/${revenue.hash}`)}
                  aria-label={`View Poll: ${revenue.question}`}
                >
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="redirect-icon" />
                </div>
                <h2 className="poll-rev-question">“{revenue.question}”</h2>
                <div className="graph-container">
                <AreaChart
  width={280}
  height={100}
  data={revenue.data} // Aggregated data
  margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
>
  <CartesianGrid strokeDasharray="3 3" stroke="#2a2a2a" />
  <XAxis
    dataKey="date"
    tick={{ fill: "#888", fontSize: 10 }}
    tickLine={false}
    axisLine={{ stroke: "#444" }}
  />
  <YAxis
  domain={[0, maxRevenue]}
  tickFormatter={(value) => parseFloat(value).toFixed(2)} // Format tick values
  tick={{ fill: "#888", fontSize: 10 }}
  tickLine={false}
  axisLine={{ stroke: "#444" }}
/>

  <Tooltip content={<CustomTooltip />} wrapperStyle={{ pointerEvents: "none" }} />
  <Area
    type="monotone"
    dataKey="revenue"
    stroke="#4caf50"
    strokeWidth={2}
    fill="rgba(76, 175, 80, 0.2)"
  />
</AreaChart>

</div>

<div className="stats">
  <p>{totalImpressions.toLocaleString()} Impressions</p>
  <hr className="stat-divider" />
  <p>{totalClicks.toLocaleString()} Clicks</p>
  <hr className="stat-divider" />
  <p>
    {revenue.totalVotes > 0
      ? `$${(
          revenueData.find((item) => item.hash === revenue.hash)?.data.reduce(
            (sum, entry) => sum + parseFloat(entry.revenue || 0),
            0
          ) / revenue.totalVotes
        ).toLocaleString(undefined, { minimumFractionDigits: 2 })}/vote`
      : "N/A"}
  </p>
  <hr className="stat-divider" />
  <p>
    Revenue:{" "}
    <span className="revenue-amount">
      ${totalRevenue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
    </span>
  </p>
</div>




              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Revenue;
