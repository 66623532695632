import React, { useRef, useEffect, useState } from 'react';
import '../App.css';
import { hsvaToHslaString } from '@uiw/color-convert';
import logger from './logger';

const ColorBar = ({
  colorPercentages = [],
  counts = {},
  labels = [],
  showConfidenceLabels = false,
  className = '',
  compressionFactor = 1,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const barRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [tooltip, setTooltip] = useState({
    visible: false,
    content: '',
    position: { top: 0, left: 0 },
  });
  const [isDragging, setIsDragging] = useState(false);
  const [currentSegmentCount, setCurrentSegmentCount] = useState(0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsVisible(entry.isIntersecting);
        });
      },
      { rootMargin: '0px', threshold: 0.01 }
    );

    if (barRef.current) {
      setHeight(barRef.current.offsetHeight);
      observer.observe(barRef.current);
    }

    return () => {
      if (barRef.current) {
        observer.unobserve(barRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (barRef.current) {
      setHeight(barRef.current.offsetHeight);
    }
  }, [isVisible]);

  // Convert counts to an array and sort them by label
  const countsArray = Object.keys(counts).map((label) => ({
    label,
    count: counts[label],
  }));
  
  const totalVotes = countsArray.reduce((sum, item) => sum + item.count, 0);

  const getPercentage = (count) =>
    totalVotes > 0 ? ((count / totalVotes) * 100).toFixed(1) : '0.0';

  // Add percentage to each label's count
  countsArray.forEach((item) => {
    item.percentage = getPercentage(item.count);
  });

  // Sort the colorPercentages array by label order to align with the counts
  const sortedGroupedColorPercentages = colorPercentages
    .filter((segment) => labels.includes(segment.classification))
    .sort(
      (a, b) => labels.indexOf(a.classification) - labels.indexOf(b.classification)
    );

  const handleMouseDown = (event) => {
    setIsDragging(true);
    handleSegmentInteraction(event);
  };

  const handleMouseMove = (event) => {
    if (isDragging) handleSegmentInteraction(event);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setTooltip({ visible: false, content: '', position: { top: 0, left: 0 } });
  };

  const handleTouchStart = (event) => {
    setIsDragging(true);
    handleSegmentInteraction(event.touches[0]);
  };

  const handleTouchMove = (event) => {
    if (isDragging) handleSegmentInteraction(event.touches[0]);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    setTooltip({ visible: false, content: '', position: { top: 0, left: 0 } });
  };

  const handleSegmentInteraction = (event) => {
    const barRect = barRef.current.getBoundingClientRect();
    const segmentIndex = Math.floor(
      ((event.clientX - barRect.left) / barRect.width) * currentSegmentCount
    );
    const segment = sortedGroupedColorPercentages[segmentIndex];
  
    if (segment) {
      const top = event.clientY - barRect.top - 60;
      const left = event.clientX - barRect.left - 20;
  
      // Extract opinion parts
      const baseOpinion = segment.opinion?.baseOpinion || 'Unknown Opinion';
      const strength = segment.opinion?.strength || '';
  
      // Combine base opinion and confidence level
      const fullOpinion = strength ? `${baseOpinion} - ${strength}` : baseOpinion;
  
      const formattedTimestamp = segment.timestamp
        ? new Date(segment.timestamp).toLocaleString()
        : 'Unknown Timestamp';
  
      setTooltip({
        visible: true,
        content: `
          <div class="tooltip-opinion">${fullOpinion}</div>
          <div class="tooltip-timestamp">${formattedTimestamp}</div>
        `,
        position: { top, left },
      });
    }
  };
  

  useEffect(() => {
    if (sortedGroupedColorPercentages.length !== currentSegmentCount) {
      setCurrentSegmentCount(sortedGroupedColorPercentages.length);
    }
  }, [sortedGroupedColorPercentages, currentSegmentCount]);

  const groupVotes = (votes) => {
    if (votes.length <= 200) return votes;
    let factor = 2;
    while (votes.length / factor > 200) factor *= 2;
    return votes.filter((_, index) => index % factor === 0);
  };

  const groupedColorPercentages = groupVotes(sortedGroupedColorPercentages);

  return (
    <div
      ref={barRef}
      className={`color-bar-wrapper ${className} ${isVisible ? 'visible' : ''}`}
      style={{ minHeight: `${height}px`, position: 'relative' }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {isVisible ? (
        <>
          <div className="tooltip-wrapper">
            {tooltip.visible && (
              <div
                className="tooltip"
                style={{
                  top: `${tooltip.position.top}px`,
                  left: `${tooltip.position.left}px`,
                  zIndex: 1000,
                }}
                dangerouslySetInnerHTML={{ __html: tooltip.content }}
              />
            )}
          </div>
          <div className="color-bar-container">
            {totalVotes === 0 ? (
              <div
                className="color-segment"
                style={{ backgroundColor: '#5d5d5d', width: '100%' }}
              />
            ) : (
              groupedColorPercentages.map((segment, index) => (
                <div
                  key={index}
                  className="color-segment"
                  style={{
                    backgroundColor: segment.color,
                    width: `${100 / groupedColorPercentages.length}%`,
                  }}
                />
              ))
            )}
          </div>
          {/* <div className="vote-counts">
            {countsArray.map(({ label, count, percentage }) => (
              <div className="vote-count" key={label}>
                <div>{`${percentage}%`}</div>
                <div>{label}</div>
              </div>
            ))}
          </div> */}
        </>
      ) : (
        <div style={{ height: `${height}px` }} />
      )}
    </div>
  );
};

export default ColorBar;
