import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './MainDashboard.css';
import MyPolls from './MyPolls';
import CreatePoll from './CreatePoll';
import ProfileIcon from './ProfileIcon';
import logger from './logger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Contact from './Contact';
import LiveDashboard from './LiveDashboard';
import PollIcon from '../assets/poll-icon.png';
import LiveIcon from '../assets/live-icon.png';
import RevIcon from '../assets/revenue-icon.png';
import BillingIcon from '../assets/billing-icon.png';
import SettingsIcon from '../assets/settings-icon.png';
import SupportIcon from '../assets/support-icon.png';
import Billing from './Billing';
import Apply from './Apply';
import { useLocation } from 'react-router-dom';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import Revenue from "./Revenue"; // Add this at the top
import ProfileSettings from "./ProfileSettings"; // Add this at the top







const MainDashboard = ({
    isLoggedIn,
    userId,
    isProfileView,
    userEmail,
    subscriptionStatus,
    username // Now passed directly from props
}) => {
    const [activeTab, setActiveTab] = useState('My Polls');
    const [showCreatePoll, setShowCreatePoll] = useState(false);
    const [showApply, setShowApply] = useState(false);
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const [totalRevenue, setTotalRevenue] = useState(0);
    const navigate = useNavigate();
    const [pollSuccessData, setPollSuccessData] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);


    const location = useLocation();

    console.log ({subscriptionStatus}) 


    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/');
        window.location.reload(); // Optional: Refresh the page
      };
    
      const toggleDropdown = () => {
        setShowDropdown((prev) => !prev);
      };
    

// Add this useEffect to detect the "openTab" parameter
useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const openTab = queryParams.get('openTab');
    if (openTab) {
        setActiveTab(openTab); // Dynamically set the active tab based on the query parameter
    }
}, [location]);

    const handleCreatePoll = (data) => {
        setActiveTab('My Polls');
        setShowCreatePoll(false);
        setPollSuccessData(data);
    };

    // useEffect(() => {
    //     if (!isLoggedIn) {
    //         navigate('/login'); // Redirect to login page if not logged in
    //     }
    // }, [isLoggedIn, navigate]);

    useEffect(() => {
        if (!userId) {
            logger.error('User ID is null or undefined');
            return;
        }

        // Fetch additional user data if needed (e.g., profile image URL)
        const fetchUserData = async () => {
            try {
                const response = await fetch(`/auth/user/${userId}`);
                const data = await response.json();
                setProfileImageUrl(data.profileImageUrl || '');

                if (subscriptionStatus === 'Creator') {
                    // Fetch revenue if user is a Creator
                    const revenueResponse = await fetch(`/api/user/${userId}/revenue`);
                    const revenueData = await revenueResponse.json();
                    const revenue = revenueData.totalRevenue?.$numberDecimal
                        ? parseFloat(revenueData.totalRevenue.$numberDecimal)
                        : parseFloat(revenueData.totalRevenue || 0);
                    setTotalRevenue(revenue);
                }
            } catch (error) {
                logger.error('Error fetching user profile or revenue:', error);
            }
        };

        fetchUserData();
    }, [userId, subscriptionStatus]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setShowCreatePoll(false);
        setShowApply(false);
    };

    const handleCreatePollClick = () => {
        setShowCreatePoll(true);
        setShowApply(false);
        setActiveTab('Create Poll'); // Set activeTab to "Create Poll"
    };

    const handleMonetizeClick = () => {
        setShowApply(true);
        setShowCreatePoll(false);
        setActiveTab('');
        setActiveTab('Apply'); // Set activeTab to "Create Poll"

    };


    const getHeadlineText = () => {
        switch (activeTab) {
            case 'My Polls':
                return 'My Polls';
            case 'Revenue':
                return 'Revenue';
            case 'Live Dashboard':
                return 'Live Dashboard';
            case 'Payment':
                return 'Payment';
            case 'Support':
                return 'Support';
            case 'Settings':
                return 'Settings';
            case 'Create Poll':
                return 'Create a New Poll'; // Add headline text for Create Poll
            case 'Apply':
                return 'Get Monetized'; // Add headline text for Create Poll
            default:
                return '';
        }
    };
    


    return (
        <>
            {/* Top Bar */}
            <div className="Root-top-bar">
        <img
          src="/logo.png"
          alt="Logo"
          className="Root-logo-image"
          onClick={() => navigate('/')}
          style={{ cursor: 'pointer' }}
        />
        <div className="Root-user-info">
          <span className="Root-username">{username}</span>
          <div className="Root-profile-container" onClick={toggleDropdown}>
            <ProfileIcon firstName={username} size={40} profileImageUrl={profileImageUrl} />
            <FontAwesomeIcon
              icon={faArrowDown}
              className="Root-dropdown-arrow"
            />
          </div>
        </div>
      </div>
      {showDropdown && (
        <div className="Root-dropdown-menu">
          <button className="Root-logout-button" onClick={handleLogout}>
            Log Out
          </button>
        </div>
      )}

            {/* Dashboard Container */}
            <div className="Root-dashboard-container">
                <div className="Root-sidebar">
                    <nav className="Root-nav-menu">
                    <button
  className="Root-create-poll"
  onClick={handleCreatePollClick}
  aria-label="Create Poll"
>
  <span className="Root-create-poll-text">Create New Poll +</span>
  <span className="Root-create-poll-icon">+</span>
</button>

                        <ul>
    <div className="Root-nav-divider-top" />

    {[{ name: 'My Polls', icon: PollIcon },
      { name: 'Revenue', icon: RevIcon },
      { name: 'Live Dashboard', icon: LiveIcon },
    ].map(({ name, icon }) => (
        <li
            key={name}
            className={`Root-nav-item ${activeTab === name ? 'Root-active' : ''} ${subscriptionStatus === 'Free' && name === 'Revenue' ? 'Root-disabled' : ''}`}
            onClick={() => {
                if (subscriptionStatus === 'Free' && name === 'Revenue') {
                    handleMonetizeClick(); // Redirect to Apply page if disabled
                } else {
                    handleTabClick(name);
                }
            }}
        >
            <img src={icon} alt={`${name} icon`} className="Root-nav-icon" />
            <span>{name}</span>
        </li>
    ))}

    <div className="Root-nav-divider" />

    {[{ name: 'Payment', icon: BillingIcon },
      { name: 'Support', icon: SupportIcon },
      // Commented out Settings
      // { name: 'Settings', icon: SettingsIcon },
    ].map(({ name, icon }) => (
        <li
            key={name}
            className={`Root-nav-item ${activeTab === name ? 'Root-active' : ''} ${subscriptionStatus === 'Free' && name === 'Payment' ? 'Root-disabled' : ''}`}
            onClick={() => {
                if (subscriptionStatus === 'Free' && name === 'Payment') {
                    handleMonetizeClick(); // Redirect to Apply page if disabled
                } else {
                    handleTabClick(name);
                }
            }}
        >
            <img src={icon} alt={`${name} icon`} className="Root-nav-icon" />
            <span>{name}</span>
        </li>
    ))}
    <div className="Root-nav-divider" />
</ul>
                    </nav>

                    {/* Sidebar Section */}
                    <div className="Root-bottom-section">
                        {subscriptionStatus === 'Free' ? (
                            <>
                                <img src="/monetize.png" alt="Logo" className="Root-monetize-image" />
                                <p className="Root-monetize-paragraph">
                                    Apply now to start making money from people voting on your polls.
                                </p>
                                <button className="Root-monetize-button" onClick={handleMonetizeClick}>
                                    Get Monetized $ <FontAwesomeIcon icon={faArrowRight} className="Root-arrow-icon" />
                                </button>
                            </>
                        ) : (
                            <div className="Root-revenue-section">
  <div className="Root-revenue-amount">
    ${(totalRevenue / 2).toFixed(2)}
  </div>
  <div className="Root-revenue-period">Payout Balance</div>
</div>

                        )}
                    </div>
                </div>

                <div className="Root-main-content">
    <div className="Root-headline">{getHeadlineText()}</div>

    {showCreatePoll ? (
        <CreatePoll
            isLoggedIn={isLoggedIn}
            userId={userId}
            username={username}
            onCreate={handleCreatePoll}
            subscriptionStatus={subscriptionStatus}
        />
    ) : showApply ? (
        <Apply userId={userId} userEmail={userEmail} />
    ) : activeTab === 'My Polls' ? (
        <MyPolls
            userId={userId}
            isLoggedIn={isLoggedIn}
            username={username}
            isProfileView={isProfileView}
            pollSuccessData={pollSuccessData}
            onCloseSuccessModal={() => setPollSuccessData(null)}
        />
    ) : activeTab === 'Support' ? (
        <Contact />
    ) : activeTab === 'Live Dashboard' ? (
        <LiveDashboard
            userId={userId}
            username={username}
            userEmail={null}
        />
    ) : activeTab === 'Payment' ? (
        <Billing userId={userId} userEmail={userEmail} />
    ) : activeTab === 'Revenue' ? (
        <Revenue userId={userId} />
    ) : (
        <p></p>
    )}

    {/* Commented out Settings */}
    {/* {activeTab === 'Settings' ? (
        <ProfileSettings
            handleLogout={handleLogout}
        />
    ) : (
        <p></p>
    )} */}
</div>

            </div>
        </>
    );
};

export default MainDashboard;
