import React, { useState, useEffect } from 'react';
import './SignUpPage.css';
import PaypalButton from '../assets/paypal-button.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import CSVViewer from './CSVViewer'; // Import the CSVViewer component
import PayPalLogo from '../assets/PayPal.png';
import Chip from '../assets/Chip.png';
import PayoutHistoryViewer from './PayoutHistoryViewer'; // Import the new component





function UpdatePaypalEmail({ userId }) {
  const [paypalEmail, setPaypalEmail] = useState('');
  const [totalRevenue, setTotalRevenue] = useState(0.0);
  const [message, setMessage] = useState('');
  const [isUserDataFetched, setIsUserDataFetched] = useState(false);
  const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
  const redirectUri = encodeURIComponent('https://opinari.io/dashboard/main?openTab=payment');

  // Fetch user data from MongoDB on mount
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setMessage(''); // Clear any previous messages
  
        const response = await fetch(`/api/user/${userId}/revenue`);
        const result = await response.json();
  
        if (response.ok) {
          // Set the stored PayPal email if available
          if (result.paypalEmail) {
            setPaypalEmail(result.paypalEmail);
            setMessage(`Connected to: ${result.paypalEmail}`);
          } else {
            setMessage('PayPal account not connected');
          }
  
          const revenue = result.totalRevenue?.$numberDecimal
            ? parseFloat(result.totalRevenue.$numberDecimal)
            : parseFloat(result.totalRevenue || 0);
          setTotalRevenue(revenue);
        } else {
          setMessage('Error fetching revenue and PayPal details');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setMessage('An error occurred while fetching data');
      } finally {
        setIsUserDataFetched(true); // Set to true after fetching
      }
    };
  
    fetchUserData();
  }, [userId]);
  

  const handlePayPalLogin = () => {
    const scope = encodeURIComponent('openid email'); // Include 'email' scope
    const state = '123456';
    const loginUrl = `https://www.sandbox.paypal.com/signin/authorize?flowEntry=static&client_id=${clientId}&response_type=code&scope=${scope}&redirect_uri=${redirectUri}&state=${state}`;

    console.log('Generated PayPal Login URL:', loginUrl);
    window.location.href = loginUrl;
  };

  // Handle the PayPal login return and exchange authorization code only if no payer ID is stored
  useEffect(() => {
    if (!isUserDataFetched) return; // Wait until user data is fetched
    if (paypalEmail) return; // Avoid making a request if we already have the email
  
    const urlParams = new URLSearchParams(window.location.search);
    const authorizationCode = urlParams.get('code');
  
    if (authorizationCode) {
      const exchangeAuthorizationCode = async () => {
        try {
          const response = await fetch('/api/paypal/token', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ authorization_code: authorizationCode, userId })
          });
          const data = await response.json();
  
          if (data.paypalEmail) {
            setPaypalEmail(data.paypalEmail);
            setMessage(`PayPal account connected: ${data.paypalEmail}`);
  
            // Remove 'code' parameter from URL
            window.history.replaceState({}, document.title, window.location.pathname);
          } else {
            setMessage('Connect your PayPal account:');
  
            // Remove 'code' parameter from URL to prevent future attempts
            window.history.replaceState({}, document.title, window.location.pathname);
          }
        } catch (error) {
          console.error('Error exchanging authorization code:', error);
          setMessage('Connect your PayPal account:');
  
          // Remove 'code' parameter from URL to prevent future attempts
          window.history.replaceState({}, document.title, window.location.pathname);
        }
      };
  
      exchangeAuthorizationCode();
    }
  }, [userId, paypalEmail, isUserDataFetched]);
  

  const handlePayout = async () => {
    try {
      if (!paypalEmail) {
        setMessage('PayPal account is required before requesting a payout.');
        return;
      }

      const response = await fetch('/api/paypal-payout', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId, amount: totalRevenue.toFixed(2) }),
      });

      const result = await response.json();
      setMessage(result.message || 'Payout processed successfully');
    } catch (error) {
      console.error('Error processing payout:', error);
      setMessage('Error processing payout');
    }
  };

  return (
    <div className="signup-page__container">
      <div className="signup-page__form-section-billing">
        <div className="billing-wrapper">
          {/* Left Column */}
          <div className="billing-left-column">
            <div className="signup-page__cards">
              {/* PayPal Connection Card */}
              <div className="signup-page__button-wrapper">
                <h2>
                  {paypalEmail ? (
                    <>
                      PayPal Connected <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#6adb6a' }} />
                    </>
                  ) : (
                    <>
                      Connect PayPal <FontAwesomeIcon icon={faExclamationCircle} style={{ color: '#ff4d4d' }} />
                    </>
                  )}
                </h2>
                {message && <p className="signup-page__message">{message}</p>}
                <button onClick={handlePayPalLogin} className="paypal-image-button">
                  <img src={PaypalButton} alt="Log in with PayPal" className="paypal-image" />
                </button>
              </div>

              {/* Revenue Summary Card styled like a credit card */}
              <div className={`credit-card ${paypalEmail ? '' : 'credit-card--disabled'}`}>
                <div className="credit-card__header">
                  <img src={PayPalLogo} alt="PayPal Logo" className="credit-card__paypal-logo" />
                </div>
                <div className="credit-card__content">
                  <h3>{paypalEmail ? 'David Stewart' : 'Not Connected'}</h3>
                  <p>Gross Revenue: ${totalRevenue.toFixed(2)}</p>
                  <div className="checkout-divider" />
                  <p>Rev Share (50%): -${(totalRevenue / 2).toFixed(2)}</p>
                  <p className="net-balance">Payout Balance: ${(totalRevenue / 2).toFixed(2)}</p>
                </div>
                <div className="credit-card__footer">
                  <img src={Chip} alt="Card Chip" className="credit-card__chip" />
                </div>
              </div>
            </div>

            {/* Ad Click History */}
            <div className="signup-page__csv-viewer">
              <CSVViewer userId={userId} />
            </div>
          </div>

         
        </div>

        {/* Get Paid Button */}
        {/* <button onClick={handlePayout} className="signup-page__custom-submit-button">
          Get Paid
        </button> */}
      </div>
    </div>
  );
    
  
}

export default UpdatePaypalEmail;
