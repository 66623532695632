// ColorWheelBuilder.js
import React, { useEffect, useRef, useState } from 'react';
import './ColorWheelBuilder.css';
import ColorWheelImage from '../assets/ColorWheelBuilder.png';

const ColorWheelBuilder = ({ options, onNodeClick }) => {
  const nodesContainerRef = useRef(null);
  const nodeElementsRef = useRef([]); // Store references to both main and sub-nodes
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleMouseOver = (e) => {
    const hoveredNode = e.currentTarget;

    nodeElementsRef.current.forEach((node) => {
      if (node === hoveredNode) {
        // Apply different scaling based on node type
        const isSubNode = node.classList.contains('sub-node');
        const scaleFactor = isSubNode ? 2.5 : 1.5;
        node.style.transform = `translate(-50%, -50%) scale(${scaleFactor})`;
      } else {
        node.style.transform = 'translate(-50%, -50%) scale(0.5)';
      }
    });
  };

  const handleMouseLeave = () => {
    nodeElementsRef.current.forEach((node) => {
      node.style.transform = 'translate(-50%, -50%) scale(1)';
    });
  };

  const createNodes = () => {
    const container = nodesContainerRef.current;

    // Clean up event listeners from previous nodes
    nodeElementsRef.current.forEach((node) => {
      node.removeEventListener('mouseover', handleMouseOver);
      node.removeEventListener('mouseleave', handleMouseLeave);
    });

    container.innerHTML = '';
    nodeElementsRef.current = [];

    const numOptions = options.length;
    const segmentSize = 360 / numOptions;
    const initialOffset = numOptions === 2 ? 0 : segmentSize / -2;

    const containerRect = container.getBoundingClientRect();
    const containerWidth = containerRect.width;
    const centerX = containerWidth / 2;
    const centerY = containerRect.height / 2;
    const radius = (containerWidth / 2) * 0.8;
    const nodeSize = containerWidth * 0.12;
    const subNodeSize = containerWidth * 0.06;

    for (let i = 0; i < numOptions; i++) {
      const angle = (segmentSize * i + initialOffset) % 360;
      const radians = (angle * Math.PI) / 180;
      const x = radius * Math.cos(radians) + centerX;
      const y = radius * Math.sin(radians) + centerY;

      const mainNode = document.createElement('div');
      mainNode.classList.add('circle-button', 'main-node');
      mainNode.style.width = `${nodeSize}px`;
      mainNode.style.height = `${nodeSize}px`;
      mainNode.style.left = `${x}px`;
      mainNode.style.top = `${y}px`;
      mainNode.style.transform = 'translate(-50%, -50%) scale(1)';

      const labelDiv = document.createElement('div');
      labelDiv.classList.add('node-label');
      labelDiv.textContent = options[i].label;
      labelDiv.style.fontSize = `${nodeSize * 0.2}px`;
      mainNode.appendChild(labelDiv);

      // Add click event to scroll to the relevant input field
      mainNode.addEventListener('click', () => onNodeClick(i));

      // Attach event listeners
      mainNode.addEventListener('mouseover', handleMouseOver);
      mainNode.addEventListener('mouseleave', handleMouseLeave);

      container.appendChild(mainNode);
      nodeElementsRef.current.push(mainNode);

      const numLevels = options[i].confidenceLevels.length;
      for (let j = 0; j < numLevels; j++) {
        const levelRadius = radius - ((numLevels - j) * (radius / (numLevels + 1)));
        const subX = levelRadius * Math.cos(radians) + centerX;
        const subY = levelRadius * Math.sin(radians) + centerY;

        const subNode = document.createElement('div');
        subNode.classList.add('sub-node');
        subNode.style.width = `${subNodeSize}px`;
        subNode.style.height = `${subNodeSize}px`;
        subNode.style.left = `${subX}px`;
        subNode.style.top = `${subY}px`;
        subNode.style.transform = 'translate(-50%, -50%) scale(1)';

        const confLabelDiv = document.createElement('div');
        confLabelDiv.classList.add('confidence-label');
        confLabelDiv.textContent = options[i].confidenceLevels[j];
        confLabelDiv.style.fontSize = `${subNodeSize * 0.2}px`;
        subNode.appendChild(confLabelDiv);

        subNode.addEventListener('click', () => onNodeClick(i, j)); // For sub-nodes

        // Attach event listeners
        subNode.addEventListener('mouseover', handleMouseOver);
        subNode.addEventListener('mouseleave', handleMouseLeave);

        container.appendChild(subNode);
        nodeElementsRef.current.push(subNode);
      }
    }
  };

  useEffect(() => {
    if (!isImageLoaded) return;

    createNodes();

    // Cleanup function to remove event listeners when component unmounts or options change
    return () => {
      nodeElementsRef.current.forEach((node) => {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, [options, isImageLoaded]);

  useEffect(() => {
    const handleResize = () => {
      if (isImageLoaded) {
        createNodes();
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isImageLoaded]);

  return (
    <div className="color-wheel-builder">
      <div className="color-wheel-container">
        <div className="color-wheel-content">
          <img
            src={ColorWheelImage}
            alt="Color Wheel"
            className="color-wheel-image"
            onLoad={() => setIsImageLoaded(true)}
          />
          <div id="nodes-container" ref={nodesContainerRef} />
        </div>
      </div>
    </div>
  );
};

export default ColorWheelBuilder;
