import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { Eye } from 'lucide-react';
import './SignUpPage.css';
import logo from '../assets/logo.png';
import bg from '../assets/DashboardShowcase.png';

const SignUpPage = ({ onSignUp }) => {
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [gender, setGender] = useState('unknown');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const recaptchaRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const pollId = location.state?.pollId;

  const handleInputChange = (setter) => (e) => {
    setError('');
    setter(e.target.value);
  };

  const handleSignUpButtonClick = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    setShowCaptcha(true);
  };

  const handleCaptchaSuccess = async (token) => {
    try {
      const response = await fetch('/verify-captcha', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token }),
      });
      const data = await response.json();
      if (data.success) {
        setShowCaptcha(false); 
        handleSignUp();
      } else {
        setError('CAPTCHA verification failed. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying CAPTCHA:', error);
      setError('CAPTCHA verification failed. Please try again.');
    }
  };

  const handleSignUp = () => {
    const payload = { username, firstName, lastName, email, password, gender, dateOfBirth };
    fetch('/auth/signup', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message) {
          setSuccessMessage('Thanks for signing up! A verification link has been sent to your email.');
          setUsername('');
          setFirstName('');
          setLastName('');
          setEmail('');
          setPassword('');
          setConfirmPassword('');
          setGender('unknown');
          setDateOfBirth('');
        } else {
          setError(data.error || 'Sign up failed');
        }
      })
      .catch((err) => console.error('Sign up error:', err));
  };

  return (
    <div className="signup-page__container">
      <div className="signup-page__form-section">
        <div className="signup-page__form-wrapper">
        <img src={logo} alt="Logo" onClick={() => navigate('/')} />
          {successMessage ? (
            <p className="signup-page__success-message">{successMessage}</p>
          ) : (
            <form onSubmit={handleSignUpButtonClick}>
              <div className="signup-page__form-group">
                <label className="signup-page__label">Username</label>
                <input
                  type="text"
                  placeholder="Choose a username"
                  value={username}
                  onChange={handleInputChange(setUsername)}
                  required
                  autoComplete="off"
                  className="signup-page__input"
                />
              </div>

              <div className="signup-page__name-fields">
                <div className="signup-page__form-group">
                  <label className="signup-page__label">First Name</label>
                  <input
                    type="text"
                    placeholder="First name"
                    value={firstName}
                    onChange={handleInputChange(setFirstName)}
                    required
                    autoComplete="off"
                    className="signup-page__input"
                  />
                </div>
                <div className="signup-page__form-group">
                  <label className="signup-page__label">Last Name</label>
                  <input
                    type="text"
                    placeholder="Last name"
                    value={lastName}
                    onChange={handleInputChange(setLastName)}
                    required
                    autoComplete="off"
                    className="signup-page__input"
                  />
                </div>
              </div>

              <div className="signup-page__form-group">
                <label className="signup-page__label">Email</label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleInputChange(setEmail)}
                  required
                  autoComplete="off"
                  className="signup-page__input"
                />
              </div>

              <div className="signup-page__form-group">
                <label className="signup-page__label">Password</label>
                <div className="signup-page__password-input">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Create a password"
                    value={password}
                    onChange={handleInputChange(setPassword)}
                    required
                    autoComplete="new-password"
                    className="signup-page__input"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="signup-page__password-toggle"
                  >
                    <Eye size={20} style={{ opacity: showPassword ? 1 : 0.5 }} />
                  </button>
                </div>
              </div>

              <div className="signup-page__form-group">
                <label className="signup-page__label">Confirm Password</label>
                <div className="signup-page__password-input">
                  <input
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="Confirm your password"
                    value={confirmPassword}
                    onChange={handleInputChange(setConfirmPassword)}
                    required
                    autoComplete="new-password"
                    className="signup-page__input"
                  />
                  <button
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    className="signup-page__password-toggle"
                  >
                    <Eye size={20} style={{ opacity: showConfirmPassword ? 1 : 0.5 }} />
                  </button>
                </div>
              </div>

              <div className="signup-page__form-group">
                <label className="signup-page__label">Gender</label>
                <select
                  value={gender}
                  onChange={handleInputChange(setGender)}
                  className="signup-page__custom-select"
                >
                  <option value="unknown">Select gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="non-binary">Non-Binary</option>
                  <option value="other">Other</option>
                </select>
              </div>

              <div className="signup-page__form-group">
                <label className="signup-page__label">Date of Birth</label>
                <input
                  type="date"
                  value={dateOfBirth}
                  onChange={handleInputChange(setDateOfBirth)}
                  required
                  autoComplete="off"
                  className="signup-page__input"
                />
              </div>

              {error && <p className="signup-page__error-message">{error}</p>}

              <div className="signup-page__form-footer">
                <button type="submit" className="signup-page__custom-submit-button">
                  Create Account
                </button>
                <p className="signup-page__custom-signin-link">
                  Already have an account? <a href="/login">Sign in</a>
                </p>
              </div>
            </form>
          )}
        </div>
      </div>

      <div className="signup-page__image-section">
        {/* <h1> Unlock the Dashboard
        </h1>
        <p>Start making money today directly from your engaged follower’s ad clicks!
        </p> */}
        <img src={bg} alt="Signup illustration" />
      </div>

      {showCaptcha && (
        <div className="signup-page__captcha-overlay">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={handleCaptchaSuccess}
            theme="dark"
          />
        </div>
      )}
    </div>
  );
};

export default SignUpPage;
