import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Poll from './Poll';
import logger from './logger';

const ReviewPoll = ({ isLoggedIn, userId, username }) => {
  const { hash } = useParams();
  const navigate = useNavigate();
  const [poll, setPoll] = useState(null);
  const [loading, setLoading] = useState(true);
  const [overrideToken, setOverrideToken] = useState('');

  useEffect(() => {
    // Clear any previously stored override token
    localStorage.removeItem('overrideToken');
    setOverrideToken('');
  }, []);

  const handleTokenSubmit = () => {
    const token = document.querySelector('.TokenDialog input').value;
    if (token.trim() === '') {
      alert('Please enter a valid token.');
      return;
    }
    localStorage.setItem('overrideToken', token); // Save token to local storage
    setOverrideToken(token);
  };

  useEffect(() => {
    if (!overrideToken) return; // Wait until token is entered

    logger.log('Fetching poll for review with hash:', hash, 'and token:', overrideToken);
    fetch(`/polls/${hash}`, {
      headers: {
        'x-override-token': overrideToken, // Include override token
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to fetch poll. Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        logger.log('Poll data for review:', data);
        setPoll(data.poll);
        setLoading(false);
      })
      .catch((error) => {
        logger.error('Error fetching poll for review:', error);
        setLoading(false);
      });
  }, [hash, overrideToken]);

  if (!overrideToken) {
    return (
      <div className="TokenDialog">
        <div>
          <h3>Enter Override Token</h3>
          <input
            type="password"
            placeholder="Enter token"
          />
          <button onClick={handleTokenSubmit}>Submit</button>
        </div>
      </div>
    );
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!poll) {
    return <div>This poll does not exist or has been deleted.</div>;
  }

  const handleReject = async () => {
    try {
      const response = await fetch(`/polls/${poll._id}/reject`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-override-token': overrideToken, // Include the override token
        },
      });
  
      if (response.ok) {
        alert('Poll rejected and moved to legacy collection!');
        navigate('/review');
      } else {
        const errorMessage = await response.text();
        console.error('Failed to reject poll:', errorMessage);
        alert('Failed to reject poll. Please try again.');
      }
    } catch (error) {
      console.error('Error rejecting poll:', error);
      alert('An error occurred while rejecting the poll.');
    }
  };
  

  const handleApprove = async () => {
    try {
      // Approve the poll
      const approveResponse = await fetch(`/polls/${poll._id}/approve`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-override-token': overrideToken, // Include the override token
        },
        body: JSON.stringify({ approved: true }),
      });
  
      if (!approveResponse.ok) {
        const errorMessage = await approveResponse.text();
        console.error('Failed to approve poll:', errorMessage);
        alert('Failed to approve poll. Please try again.');
        return;
      }
  
      // Delete all votes for the poll
      const deleteVotesResponse = await fetch(`/polls/${poll._id}/votes`, {
        method: 'DELETE',
        headers: {
          'x-override-token': overrideToken, // Include the override token
        },
      });
  
      if (!deleteVotesResponse.ok) {
        const errorMessage = await deleteVotesResponse.text();
        console.error('Failed to delete votes:', errorMessage);
        alert('Poll approved, but failed to delete votes. Please check the logs.');
        return;
      }
  
      alert('Poll approved successfully, and all votes deleted!');
      navigate('/review');
    } catch (error) {
      console.error('Error approving poll:', error);
      alert('An error occurred while approving the poll.');
    }
  };
  

  return (
    <div className="StandalonePollWrapper">
      <div className="StandalonePoll" style={{ padding: '20px', position: 'relative' }}>
        <div className="Poll-item">
          <Poll
            creatorId={poll.creator._id}
            pollId={poll._id}
            question={poll.question}
            labels={poll.labels}
            logo={poll.logo || "/logo-poll.png"}
            logoFill={poll.logoFill}
            votes={poll.votes}
            hash={hash}
            creatorUsername={poll.creatorUsername}
            isLoggedIn={isLoggedIn}
            userId={userId}
            username={username}
            voteAuthenticationMethod="0"
            confidenceLabels={poll.confidenceLabels}
            imageAction={poll.imageAction}
            roundedCorners={poll.roundedCorners}
            startDate={poll.startDate}
            endDate={poll.endDate}
            scheduledPoll={poll.scheduledPoll}
            useCaptcha={poll.useCaptcha}
            totalVotes={poll.totalVotes}
            isTemporary={poll.isTemporary}
            scrollToBottom={() => {}}
            isLegacyPoll={poll.isLegacyPoll}
            isAdvertising={poll.isAdvertising}
            category={poll.category}
          />
        </div>
        <div className="ReviewActions">
          <button onClick={handleApprove}>Approve</button>
          <button onClick={handleReject}>Reject</button>
        </div>
      </div>
    </div>
  );
};

export default ReviewPoll;
