import React, { useState, useEffect } from 'react';
import Poll from './Poll'; // Import the Poll component
import PollSuccessModal from './PollSuccessModal'; // Import PollSuccessModal

const MyPolls = ({ 
    userId, 
    username, 
    userEmail, 
    isLoggedIn, 
    isProfileView, 
    pollSuccessData, 
    onCloseSuccessModal 
}) => {
    const [polls, setPolls] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [currentSkip, setCurrentSkip] = useState(0); // Tracks the current skip for sequential loading
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true); // Whether there are more polls to load
    const LIMIT = 1; // Load one poll at a time

    useEffect(() => {
        if (pollSuccessData) {
            setShowSuccessModal(true);
        }
    }, [pollSuccessData]);

    // Function to fetch the next poll
    const fetchNextPoll = async () => {
        if (isLoading || !hasMore) return;

        setIsLoading(true);
        try {
            const response = await fetch(`/polls?creator=${userId}&limit=${LIMIT}&skip=${currentSkip}&sort=createdAtDesc`);
            const data = await response.json();

            if (data.polls && data.polls.length > 0) {
                setPolls((prevPolls) => [...prevPolls, ...data.polls]); // Append the new poll
                setCurrentSkip((prevSkip) => prevSkip + LIMIT); // Increment skip for the next fetch
            } else {
                setHasMore(false); // No more polls to fetch
            }
        } catch (error) {
            console.error('Error fetching user polls:', error);
        } finally {
            setIsLoading(false);
        }
    };

   useEffect(() => {
    if (!isLoading && hasMore) {
        fetchNextPoll(); // Handles both initial fetch and subsequent fetches
    }
}, [isLoading, hasMore]);


    const handleDeletePoll = (pollId) => {
        setPolls((polls) => polls.filter((poll) => poll._id !== pollId));
    };

    const closeModal = () => {
        setShowSuccessModal(false);
        onCloseSuccessModal(); // Reset modal state in the parent component
    };

    return (
        <div className="Root-grid-container">
            {showSuccessModal && pollSuccessData && (
                <PollSuccessModal
                    pollLink={pollSuccessData.pollLink}
                    isTemporary={pollSuccessData.isTemporary}
                    onClose={closeModal}
                />
            )}

            {polls.length > 0 ? (
                polls.map((poll) => (
                    <div
                        key={poll._id}
                        className="Poll-item"
                        style={{ position: 'relative', opacity: poll.approved ? 1 : 1 }}
                    >
                        {!poll.approved && (
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgb(15 15 15 / 55%)',
                                    zIndex: 10,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    color: 'white',
                                    fontSize: '24px',
                                    borderRadius: '10px',
                                }}
                            >
                                Poll Under Review
                            </div>
                        )}

                        <Poll
                            pollId={poll._id}
                            question={poll.question}
                            labels={poll.labels}
                            logo={poll.logo || "/logo-poll.png"}
                            logoFill={poll.logoFill}
                            votes={poll.votes}
                            hash={poll.hash}
                            creatorUsername={poll.creatorUsername}
                            creatorId={poll.creator}
                            isLoggedIn={isLoggedIn}
                            userId={userId}
                            username={username}
                            onDelete={() => handleDeletePoll(poll._id)}
                            isProfileView={true}
                            voteAuthenticationMethod={poll.voteAuthenticationMethod}
                            confidenceLabels={poll.confidenceLabels}
                            imageAction={poll.imageAction}
                            roundedCorners={poll.roundedCorners}
                            startDate={poll.startDate}
                            endDate={poll.endDate}
                            scheduledPoll={poll.scheduledPoll}
                            userEmail={userEmail}
                            totalVotes={poll.totalVotes}
                            isTemporary={poll.isTemporary}
                            isLegacyPoll={poll.isLegacyPoll}
                            approved={poll.approved}
                        />
                    </div>
                ))
            ) : (
                <p>{isLoading ? 'Loading polls...' : 'No polls available. Create a new poll to get started.'}</p>
            )}

            {isLoading && <p>Loading next poll...</p>}
        </div>
    );
};

export default MyPolls;
