import React, { useState, useEffect, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './SignUpPage.css'; // Use the same CSS for layout consistency
import logo from '../assets/logo.png';
import MobileBg from '../assets/bg.png';
import DesktopBg from '../assets/bg.png';

const ContactSupport = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState('');
  const recaptchaRef = useRef(null);

  useEffect(() => {
    const updateBackgroundImage = () => {
      setBackgroundImage(window.innerWidth < 768 ? MobileBg : DesktopBg);
    };
    updateBackgroundImage();
    window.addEventListener('resize', updateBackgroundImage);
    return () => window.removeEventListener('resize', updateBackgroundImage);
  }, []);

  const handleInputChange = (setter) => (e) => {
    setError('');
    setter(e.target.value);
  };

  const handleCaptchaSuccess = async (token) => {
    try {
      const response = await fetch('/verify-captcha', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token }),
      });
      const data = await response.json();
      if (data.success) {
        setCaptchaVerified(true);
        handleSubmit(); // Proceed with form submission after CAPTCHA verification
      } else {
        setError('CAPTCHA verification failed. Please try again.');
        setShowCaptcha(false);
      }
    } catch (error) {
      setError('CAPTCHA verification failed. Please try again.');
      setShowCaptcha(false);
    }
  };

  const handleSubmitButtonClick = (e) => {
    e.preventDefault();
    setShowCaptcha(true);
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch('/api/send-support-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name, email, message }),
      });

      if (response.ok) {
        setIsSubmitted(true);
      } else {
        const data = await response.json();
        setError(data.error || 'Failed to send message. Please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again later.');
    }
  };

  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  };

  return (
    <div className="signup-page__container">
      <div style={backgroundStyle}></div>
      <div className="signup-page__form-section-centerd">
        <div className="signup-page__form-wrapper">
          <h2>Contact Support</h2>
          <br></br>
          {!isSubmitted ? (
            <form onSubmit={handleSubmitButtonClick} className="signup-page__form">
              <div className="signup-page__form-group">
                <label className="signup-page__label">Name</label>
                <input
                  type="text"
                  placeholder="Your Name"
                  value={name}
                  onChange={handleInputChange(setName)}
                  required
                  autoComplete="off"
                  className="signup-page__input"
                />
              </div>
              <div className="signup-page__form-group">
                <label className="signup-page__label">Email</label>
                <input
                  type="email"
                  placeholder="Your Email"
                  value={email}
                  onChange={handleInputChange(setEmail)}
                  required
                  autoComplete="off"
                  className="signup-page__input"
                />
              </div>
              <div className="signup-page__form-group">
                <label className="signup-page__label">Message</label>
                <textarea
                  placeholder="Your Message"
                  value={message}
                  onChange={handleInputChange(setMessage)}
                  required
                  className="signup-page__input"
                  style={{ fontFamily: 'Poppins' }}
                />
              </div>
              {error && <p className="signup-page__error-message">{error}</p>}
              <button type="submit" className="signup-page__custom-submit-button">Send Message</button>
            </form>
          ) : (
            <div className="signup-page__success-message">
              <h2>Thank you for your message!</h2>
              <p>We'll get back to you as soon as possible.</p>
            </div>
          )}
        </div>
      </div>

      {/* CAPTCHA Overlay */}
      {showCaptcha && (
        <div className="signup-page__captcha-overlay">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={handleCaptchaSuccess}
            theme="dark"
          />
        </div>
      )}
    </div>
  );
};

export default ContactSupport;
