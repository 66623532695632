import React from 'react';
import './Privacy.css';
import DesktopBG from '../assets/desktop-bg-login.jpg';
import MobileBG from '../assets/mobile-bg-login.jpg';

const UserAgreement = () => {
    const isDesktop = window.innerWidth >= 768;

    const backgroundStyle = {
        backgroundImage: `url(${isDesktop ? DesktopBG : MobileBG})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: -1,
    };

    return (

        <div className="PrivacyPage-container">
          <section className="PrivacyPage-section">
  <h2 className="PrivacyPage-subheading">User Agreement</h2>
  <p className="PrivacyPage-paragraph">
    (a) By using our services, you agree to abide by this User Agreement. This agreement governs your use of the Opinari services, including but not limited to, accessing and interacting with polls, creating content, and engaging with other users.
  </p>
  <p className="PrivacyPage-paragraph">
    (b) You agree to use our services only for lawful purposes and in accordance with our Terms of Service and Privacy Policy. You must not:
    <br />1. Violate any applicable laws, regulations, or guidelines.
    <br />2. Engage in fraudulent, deceptive, or harmful activities.
    <br />3. Submit, upload, or distribute any content that is offensive, defamatory, or infringes on the rights of others.
    <br />4. Attempt to gain unauthorized access to our systems or interfere with the operation of our services.
  </p>
  <p className="PrivacyPage-paragraph">
    (c) If you use PayPal for payments or payouts, you agree to:
    <br />1. Provide accurate and current PayPal account information.
    <br />2. Authorize us to process transactions on your behalf as outlined in our Privacy Policy.
    <br />3. Comply with PayPal’s terms and conditions, available at <a href="https://www.paypal.com/us/webapps/mpp/ua/useragreement-full" target="_blank">PayPal User Agreement</a>.
  </p>
  <p className="PrivacyPage-paragraph">
    (d) Opinari reserves the right to suspend or terminate your access to our services if you violate this agreement, our Terms of Service, or Privacy Policy, or if we have reason to believe your actions may cause harm to us, other users, or third parties.
  </p>
  <p className="PrivacyPage-paragraph">
    (e) If you have questions about this User Agreement or need assistance, please contact us at <a href="mailto:support@Opinari.io">support@Opinari.io</a>.
  </p>
</section>
        </div>
      );
    
};

export default UserAgreement;